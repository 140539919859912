<template>
  <b-card title="Edit Account" class="m-2">
    <b-alert show variant="secondary" v-if="firstLogin"
      >Welcome to Eunoe. Please fill out the form below.</b-alert
    >
    <div>
      <UserEditor
        :userId="this.user._id"
        @cancel="onCancel()"
        @save="onSave()"
      />
    </div>
  </b-card>
</template>

<script>
import API from "@/api";
import UserEditor from "@/components/UserEditor.vue";

export default {
  name: "Account",
  components: {
    UserEditor,
  },
  data: () => ({
    user: {},
  }),
  created() {
    this.user = API.currentUser.customData;
  },
  computed: {
    firstLogin() {
      return API.firstLogin;
    },
  },
  methods: {
    onCancel() {
      if (this.firstLogin) {
        this.$router.push("/dashboard/");
      } else {
        this.$router.push("/account/");
      }
    },
    async onSave() {
      await API.refresh();
      if (this.firstLogin) {
        this.$router.push("/dashboard/");
      } else {
        this.$router.push("/account/");
      }
    },
  },
};
</script>
