<template>
  <div>
    <b-card v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </b-card>

    <b-card v-if="saving" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </b-card>

    <b-form @submit="onSubmit" v-if="!loading && !saving">
      <b-card title="Contact" class="mb-2">
        <b-form-group
          id="input-group-name"
          label="Full Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            required
            placeholder="Enter full name."
            v-model="user.name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          label="Email address:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            type="email"
            required
            placeholder="Enter email address."
            v-model="user.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-phone"
          label="Phone number:"
          label-for="input-phone"
        >
          <b-form-input
            id="input-phone"
            type="tel"
            placeholder="Enter phone number."
            v-model="user.phone"
          ></b-form-input>
        </b-form-group>

        <b-form-checkbox
          id="checkbox-messages"
          v-model="user.receiveMessages"
          name="checkbox-messages"
        >
          Opt into email updates.
        </b-form-checkbox>

        <b-form-checkbox
          id="checkbox-share-data"
          v-model="user.shareData"
          class="mt-2"
          name="checkbox-share-data"
        >
          Share data
        </b-form-checkbox>
      </b-card>

      <b-card title="Personal" class="mb-2">
        <b-form-group
          id="input-group-birthday"
          label="Birthday:"
          label-for="input-birthday"
        >
          <b-form-input
            id="input-birthday"
            type="date"
            v-model="user.birthday"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-pronouns"
          label="Preferred Pronouns:"
          label-for="input-pronouns"
        >
          <b-form-input
            id="input-pronouns"
            placeholder="Enter prefered pronouns."
            v-model="user.pronouns"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-gender"
          label="Gender:"
          label-for="input-gender"
        >
          <b-form-select
            id="input-gender"
            v-model="user.gender"
            :options="genders"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-occupation"
          label="Occupation/Speciality:"
          label-for="input-occupation"
        >
          <b-form-input
            id="input-occupation"
            placeholder="Enter occupation/speciality"
            v-model="user.occupation"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <!-- <b-form-group
        id="input-group-birthday"
        label="Birthday:"
        label-for="input-birthday"
      >
        <b-form-datepicker
          id="input-birthday"
          v-model="user.birthday"
        ></b-form-datepicker>
      </b-form-group> -->

      <b-card title="Location" class="mb-2">
        <b-form-group label="City:" label-for="nested-city">
          <b-form-input id="nested-city" v-model="user.city"></b-form-input>
        </b-form-group>

        <b-form-group label="State:" label-for="nested-state">
          <b-form-input id="nested-state" v-model="user.state"></b-form-input>
        </b-form-group>

        <b-form-group label="Country:" label-for="nested-country">
          <b-form-input
            id="nested-country"
            v-model="user.country"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <b-button type="submit" variant="primary" class="mr-1" size="sm"
        ><b-icon icon="check-circle-fill" aria-hidden="true"></b-icon> Save
        Changes</b-button
      >

      <b-button @click="onCancel" variant="danger" size="sm"
        ><b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
        Cancel</b-button
      >
    </b-form>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ user }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import API from "@/api";

export default {
  props: ["userId"],
  data() {
    return {
      user: {},
      loading: false,
      saving: false,
      genders: [
        "Male",
        "Female",
        "Cis-Woman",
        "Cis-Man",
        "Trans-Woman",
        "Trans-Man",
        "Non-Binary",
        "Other",
      ],
    };
  },
  created() {
    if (this.userId) {
      this.fetchData();
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    async onSubmit(event) {
      event.preventDefault();

      this.saving = true;

      if (this.user._id == null) {
        console.log("Inserting new user.");
        let result = await API.insert("users", this.user).catch((error) => {
          console.log(error);
        });
        console.log(result);
      } else {
        console.log("Updating user.");
        console.log(this.user);

        let filter = {
          _id: this.user._id,
        };

        let updateData = { ...this.user };
        delete updateData._id;
        delete updateData.realmId;
        delete updateData.admin;
        delete updateData.password;
        delete updateData.lastLogin;

        console.log(updateData);

        let update = { $set: updateData };
        let result = await API.update("users", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
        console.log(result);
      }
      this.$emit("save");
    },
    async fetchData() {
      this.loading = true;

      const BSON = require("bson");
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("users");

      let userId = new BSON.ObjectID(this.userId);
      let filter = {
        _id: userId,
      };

      const result = await mongoCollection.findOne(filter);

      if (result != null) {
        this.user = result;
      }

      if (this.user.receiveMessages == undefined) {
        console.log("Setting defaults.");
        this.user.receiveMessages = true;
      }

      this.loading = false;
    },
  },
};
</script>