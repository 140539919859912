<template>
  <b-card title="Account" class="m-2">
    <b-list-group>
      <b-list-group-item>{{
        this.user.name || "Name Unknown"
      }}</b-list-group-item>
      <b-list-group-item>{{ this.user.email }}</b-list-group-item>
    </b-list-group>

    <b-button to="/account/edit" variant="primary" class="mt-2 mr-1" size="sm">
      <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Edit
    </b-button>

    <b-button v-on:click="logout()" variant="danger" class="mt-2" size="sm">
      <b-icon icon="power" aria-hidden="true"></b-icon> Logout
    </b-button>
  </b-card>
</template>

<script>
import API from "@/api";

export default {
  name: "Account",
  data: () => ({
    user: {},
  }),
  created() {
    this.user = API.currentUser.customData;
  },
  methods: {
    async logout() {
      API.logout().then(() => {
        this.$router.push("/login");
      }).catch(() => {
        localStorage.clear();
        window.reload();
      });
    },
  },
};
</script>
